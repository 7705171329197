import React from 'react'

import { StaticQuery, graphql } from 'gatsby'

import styled, { createGlobalStyle } from 'styled-components'

import Nav from './navigation'
import Footer from './footer'

const GlobalStyles = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css?family=Montserrat:400,700|Oswald:400,700");
  
  :root {
    --bg-gray: #F3F3F3;
    --main-text: #888585;
    --heading-text: #3c3838;
    --deep-red: #66121B;
    --dark-bg: #383737;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    font-family: Montserrat, sans-serif;
    color: var(--main-text);
    scroll-behavior: smooth;

  }

  body {
    background: var(--bg-gray);
    min-width: 330px;
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Oswald, sans-serif;
    color: var(--heading-text);
  }

  .white-text {
    color: white;
  }
`

const Margins = styled.div`
  margin: auto;
  max-width: 960px;
`

const Foreground = styled.div`
  background: white;
  padding: 32px;
  margin: 128px auto;
  width: 100%;
  font-size: 0.9em;
  font-weight: 300;
  line-height: 28px;

  @media only screen and (max-width: 1000px) {
    width: 90%;
  }

  @media only screen and (max-width: 768px) {
    margin: 64px auto;
    padding: 48px;
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <div>
        <GlobalStyles />

        <Margins>
          <Nav />

          <main>
            <Foreground>{children}</Foreground>
          </main>
        </Margins>

        <Footer />
      </div>
    )}
  />
)

export default Layout
