import React, { useState } from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import MenuButton from './menu-button'
import MenuOverlay from './menu-overlay'
import TopButton from './top-button'

const Navigation = styled.nav`
  position: absolute;
  top: 12px;
  display: flex;
  padding: 24px;
  width: -webkit-fill-available;
  max-width: 960px;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  img,
  p {
    margin-left: 4px;
  }

  p {
    font-size: 0.8em;
    text-align: center;
    text-decoration: none;
    font-style: italic;
    color: white;
  }

  @media only screen and (max-width: 768px) {
    background: var(--dark-bg);
    position: fixed;
    top: 0;

    p {
      font-size: 0.66em;
    }
  }
`

const BlankSpace = styled.span`
  flex: 1;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const Menu = styled.div`
  display: block;
  transition: left 500ms;

  @media only screen and (max-width: 768px) {
    background: var(--deep-red);
    position: fixed;
    top: 0;
    left: ${props => (props.isMenuOpen === true ? '0' : '-52%')};
    bottom: 0;
    width: 52%;
    z-index: 2;
  }
`

const StyledLink = styled(Link)`
  background: rgba(101, 8, 17, 0.7);
  position: relative;
  display: inline-block;
  padding: 8px;
  margin: 4px;
  border: 1px solid #5b0710;
  border-radius: 24px;
  text-decoration: none;
  font-size: 0.95em;
  color: white;
  transition: background 240ms, border-color 240ms;

  :hover,
  :active {
    background: var(--deep-red);
    border-color: rgba(136, 133, 133, 0.6);
  }

  :hover div {
    display: block;
    opacity: 1;
  }

  @media only screen and (max-width: 768px) {
    display: block;
    opacity: 1;
    margin: 0;
    padding: 16px 32px;
    width: 100%;
    border-radius: 0;
  }
`

const SubMenu = styled.div`
  position: absolute;
  display: none;
  left: 0;
  top: 100%;
  opacity: 0;
  transition: opacity 500ms;

  @media only screen and (max-width: 768px) {
    position: relative;
    padding-left: 0;
    margin-top: 12px;
  }
`

const SubLink = styled(StyledLink)`
  background: var(--deep-red);
  display: block;
  opacity: 1;

  @media only screen and (max-width: 768px) {
    border: none;
    padding-left: 0;
  }
`

const LogoLink = styled(Link)`
  text-decoration: none;

  img {
    height: 6em;

    @media only screen and (max-width: 860px) {
      height: 4.5em;
    }
    @media only screen and (max-width: 768px) {
      height: 4em;
    }
  }
`

function Nav () {
  const [isMenuOpen, toggleMenu] = useState(false)
  // const [isButtonVisible, toggleTopButton] = useState(false)
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              description
            }
          }
          allContentfulBasicPage {
            edges {
              node {
                title
                slug
                contentful_id
                subPages {
                  title
                  slug
                }
              }
            }
          }
          allContentfulMenu(filter: { menuTitle: { eq: "Navigation" } }) {
            edges {
              node {
                menuTitle
                menuLogo {
                  title
                  description
                  fluid {
                    src
                    aspectRatio
                    srcSet
                  }
                }
                links {
                  json
                }
              }
            }
          }
        }
      `}
      render={props => (
        <Navigation>
          <MenuButton toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
          <MenuOverlay toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />

          <div>
            <LogoLink to="/">
              <img
                src={props.allContentfulMenu.edges[0].node.menuLogo.fluid.src}
                alt="Bodytunes Logo"
              />
              <p>{props.site.siteMetadata.description}</p>
            </LogoLink>
          </div>

          <BlankSpace />

          <Menu isMenuOpen={isMenuOpen}>
            <StyledLink to="/">Home</StyledLink>
            {props.allContentfulMenu.edges[0].node.links.json.content.map(
              item => (
                <StyledLink
                  to={
                    item.content.filter(
                      content => content.nodeType === 'entry-hyperlink'
                    )[0].data.target.fields.slug['en-US']
                  }
                  key={
                    item.content.filter(
                      content => content.nodeType === 'entry-hyperlink'
                    )[0].data.target.sys.id
                  }
                >
                  {
                    item.content.filter(
                      content => content.nodeType === 'entry-hyperlink'
                    )[0].content[0].value
                  }

                  {item.content[1].data.target.fields.subPages && (
                    <SubMenu>
                      {item.content[1].data.target.fields.subPages['en-US'].map(
                        link => (
                          <SubLink to={link.fields.slug['en-US']}>
                            {link.fields.title['en-US']}
                          </SubLink>
                        )
                      )}
                    </SubMenu>
                  )}
                </StyledLink>
              )
            )}
          </Menu>

          <TopButton />
        </Navigation>
      )}
    />
  )
}

export default Nav
