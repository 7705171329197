import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import upArrow from '../images/round-keyboard_arrow_up-24px.svg'

const TopButton = styled.img`
  background: var(--deep-red);
  position: fixed;
  padding: 10px;
  right: 24px;
  border-radius: 3px;
  bottom: ${props => (props.scrollY >= 350 ? '24px' : '-5%')};
  transition: bottom 150ms 500ms;
`

function Button () {
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollHeight(window.scrollY)
    })
  }, [])
  const [scrollHeight, setScrollHeight] = useState(0)
  return (
    <a href="#">
      <TopButton src={upArrow} alt="Scroll to top" scrollY={scrollHeight} />
    </a>
  )
}

export default Button
