import React from 'react'
import styled from 'styled-components'

const Overlay = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    background: rgba(0, 0, 0, 0.5);
    display: ${props => (props.isMenuOpen === true ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
`

function MenuOverlay (props) {
  return (
    <Overlay
      onClick={() => props.toggleMenu(!props.isMenuOpen)}
      isMenuOpen={props.isMenuOpen}
    />
  )
}

export default MenuOverlay
