import React from 'react'
import styled from 'styled-components'
import { Link, graphql, StaticQuery } from 'gatsby'

import FBLogo from '../images/facebook.svg'
import YTLogo from '../images/youtube.svg'

const Foot = styled.footer`
  background: var(--dark-bg);
  padding: 24px;
`

const HR = styled.hr`
  width: 100vw;
  margin: auto;
  position: absolute;
  left: 0;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
`

const Margin = styled.div`
  max-width: 960px;
  margin: auto;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;

  h3 {
    color: white;
  }

  a:hover {
    color: var(--deep-red);
  }
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;

  div {
    display: inline-block;
    margin: 48px 12px;
    padding: 16px;
    border: 1px solid var(--main-text);
    border-radius: 50%;
    transition: background 220ms, border-color 220ms;

    @media only screen and (max-width: 400px) {
      flex-direction: column;
    }

    :hover {
      background: var(--deep-red);
      border-color: var(--deep-red);
    }
  }
`

const FlexContainer = styled(Flex)`
  flex-wrap: wrap;
`

const Fill = styled.div`
  flex: 1;
  padding: 32px 24px;
  margin-left: 24px;

  h3 {
    margin-bottom: 32px;
  }

  p {
    line-height: 28px;
    font-size: 0.8em;
  }

  a {
    text-decoration: none;
    transition: color 210ms;
    :hover {
      color: var(--deep-red);
    }
  }

  .white {
    color: white;
  }
`

const Logo = styled(Fill)`
  padding: 24px 0;
  margin-left: 0;

  p {
    margin-top: 12px;
    line-height: 22px;
  }

  span {
    font-style: italic;
    opacity: 0.9;
    color: white;
  }

  .margin-top {
    margin-top: 72px;
    margin-bottom: 28px;
  }
`

const StyledLink = styled(Link)`
  position: relative;
  display: block;

  ::before {
    content: ">";
    position: absolute;
    left: -12px;
  }
`

function Footer () {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              description
            }
          }
          allContentfulMenu(filter: { menuTitle: { eq: "Footer" } }) {
            edges {
              node {
                menuTitle
                menuLogo {
                  title
                  description
                  fluid {
                    src
                    aspectRatio
                    srcSet
                  }
                }
                links {
                  content {
                    content {
                      content {
                        value
                        nodeType
                      }
                      data {
                        target {
                          sys {
                            id
                          }
                          fields {
                            title {
                              en_US
                            }
                            slug {
                              en_US
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={props => (
        <Foot>
          <Margin>
            <Flex>
              <Icon>
                <a
                  href="https://www.facebook.com/bodytunes.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <img src={FBLogo} alt="Facebook logo" />
                  </div>
                </a>
                <span className="white-text"> FACEBOOK </span>
              </Icon>
              <Icon>
                <a
                  href="https://www.youtube.com/channel/UCmt_zvXE3bjBpc0jRQIdhhg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <img src={YTLogo} alt="Youtube logo" />
                  </div>
                </a>
                <span className="white-text"> YOUTUBE </span>
              </Icon>
            </Flex>

            <HR />

            <FlexContainer>
              <Logo>
                <Link to="/">
                  <img
                    src={
                      props.allContentfulMenu.edges[0].node.menuLogo.fluid.src
                    }
                    alt="Company Logo"
                  />
                </Link>
                <p>
                  <span> {props.site.siteMetadata.description} </span> <br />
                  Herzlich Willkommen auf der Website von Body Tunes - <br />
                  dem Spezialisten für EMS - Training, Personal Training,
                  Körperformung und Leistungsdiagnose.
                </p>
                <p className="margin-top">
                  <a className="white" href="http://www.sb-konzept.de/">
                    SB KONZEPT GmbH
                  </a>
                  © 2017 All Rights reserved
                </p>
              </Logo>
              <Fill>
                <h3> LINKS </h3>
                {props.allContentfulMenu.edges[0].node.links.content.map(
                  link => (
                    <p>
                      <StyledLink
                        to={link.content[1].data.target.fields.slug.en_US
                          .toLowerCase()
                          .replace(/\s/g, '-')
                          .replace(/ä/g, 'ae')
                          .replace(/ë/g, 'ee')
                          .replace(/ü/g, 'ue')}
                        key={link.content[1].data.target.sys.id}
                      >
                        {link.content[1].content[0].value}
                      </StyledLink>
                    </p>
                  )
                )}
              </Fill>
              <Fill>
                <h3> KONTAKT </h3>
                <p>
                  Münchner Str.34 <br />
                  D - 85354 Freising <br />
                  Telefon:{' '}
                  <a className="white" href="tel:+4981615373050">
                    +49(0) 81 61 - 53 73 050
                  </a>
                  <br />
                  Email:{' '}
                  <a className="white" href="mailto:info@bodytunes.de">
                    info @bodytunes.de
                  </a>
                </p>
              </Fill>
            </FlexContainer>
          </Margin>
        </Foot>
      )}
    />
  )
}

export default Footer
