import React from 'react'
import styled from 'styled-components'

import MenuIcon from '../images/baseline-menu-24px.svg'

const MenuButton = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    position: absolute;
    display: block;
    padding: 12px;
    left: 3%;

    img {
      height: min-content;
    }
  }
`

function Menu (props) {
  return (
    <MenuButton onClick={() => props.toggleMenu(!props.isMenuOpen)}>
      <img src={MenuIcon} alt="Mobile menu button" />
    </MenuButton>
  )
}

export default Menu
